<template>
    <div v-if="isOpen" class="menu-overlay" @click="closeMenu">
      <div class="menu-content" @click.stop>
        <nav>
          <ul>
            <li><a href="https://www.epostbox.de/kontakt/">Kontakt</a></li>
            <li><a href="#">Rechtliches</a></li>
            <li><a href="https://www.epostbox.de/datenschutz/">Datenschutz</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FullScreenMenu',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closeMenu() {
        this.$emit('toggle-menu');
      }
    }
  };
  </script>
  
  <style scoped>
.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/epostbox_auth_bg.png');
  background-position: center;
  background-size: cover; /* Ensures the background image is zoomed and covers the entire area */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: transform 0.5s ease, opacity 0.5s ease;
}
  
  .menu-overlay-enter-active {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .menu-overlay-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
  
  .menu-overlay-leave-active {
    transform: translateX(0);
    opacity: 1;
  }
  
  .menu-overlay-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .menu-content {
    text-align: center;
  }
  
  .menu-content nav ul {
    list-style: none;
    padding: 0;
  }
  
  .menu-content nav ul li {
    margin: 20px 0;
  }
  
  .menu-content nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 24px;
  }
  </style>
  