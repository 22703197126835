<template>
  <div style="background-color: #007bff;">
    <div class="container">
      <div class="content">
        <div class="navbar" style="margin-top: 48px;">
          <img :src="imageSrc" alt="Descriptive Text" />
        </div>
        <h1 style="font-size: 50px; margin-top: 200px; text-align: left; margin-right: 100px; ">
          {{ $t('Variant2View_1_1') }} <br> {{ $t('Variant2View_1_2') }}</h1>
        <label for="" style="text-align: left; color: white;"> {{ $t('Variant2View_2') }} <br>
          <a style="text-decoration: none; color: white;">{{ $t('Variant2View_3') }}</a>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Variant1View',
  computed: {
    imageSrc() {
      switch (this.$route.name) {
        case 'startpage':
          return require('@/assets/nolas_logo_black.svg');
        default:
          return require('@/assets/nolas_logo_white.svg');
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  height: 100vh;
  background-color: #007bff;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 72vh;
  overflow: hidden;
  padding: 0px 25px 0px 25px;
}

.content h1 {
  font-size: 30px;
  font-weight: 700;
  margin: 16px 0;
  color: white;
}

.content p {
  font-weight: 400;
  font-size: 16px;
  padding: 0px 10px;
  color: white;
}
</style>
