import Vue from 'vue';
import App from './App.vue';
import router from './router';

import VueI18n from 'vue-i18n';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Install vue-i18n plugin
Vue.use(VueI18n);

// Import your locale files
import en from './locales/en';
import de from './locales/de';

// Create the messages object for i18n
const messages = {
  en,
  de,
};

// Create a VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // Set default locale to German
  fallbackLocale: 'en',
  messages,
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
  