<template>
    <!-- Loading Spinner -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner"></div>
        </div>
    </div>

    <!-- Main Content -->
    <div v-else>
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="
                width: 92px; 
                height: 92px; 
                margin-bottom: 28px;" alt="Descriptive Text" class="text-center">
                    <p class="page-description">{{ $t('LinkExpiredView_1') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import MismatchImage from '@/assets/Frame 627847.svg'


export default {
    name: 'LinkExpiredView',
    data() {
        return {
            imageSrc: MismatchImage,
            loading: true,
            dataLoaded: false,
        };
    },
    methods: {
        checkIfLoaded() {
            if (this.dataLoaded) {
                this.loading = false
            }
        },
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src
            })
        }
    },
    created() {

        this.preLoadImage(this.imageSrc)
            .then(() => {
                this.dataLoaded = true;
                this.checkIfLoaded()
            })
            .catch(() => {
                this.checkIfLoaded();
            })

    },
    components: {
        AppNavbar,
    },
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Existing Styles */
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 25vh;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 30px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}
</style>