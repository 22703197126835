import Vue from 'vue';
import VueRouter from 'vue-router';
import StartView from '../views/StartView.vue'
import LinkExpiredView from '../views/linkExpiredView.vue'
import TokenInvalidView from '../views/TokenInvalidView.vue'
import UserAlreadyRegisteredView from '../views/UserAlreadyRegistredView.vue'
import Variant2View from '../views/Variant2View.vue'
import CustomersView from '../views/CustomersView.vue'
import ExistingCustomerView from '../views/ExistingCustomerView.vue'
import NewCustomerReg from '../views/NewCustomerView.vue'
import SetNewPassword from '../views/SetNewPass.vue'
import AddressMismatch from '../views/AddressMismactch.vue'
import AuthConfirmed from '../views/Authconfirmed.vue'
import VerificationRequired from '../views/verificationRequired.vue'
import ExistingCustomerAuthentication from '../views/ExistingCusAuthentication.vue'
import AddressCreate from '../views/AddressCreate.vue'
import ConfirmationWaitingView from '../views/ConfirmationWaitingView.vue'
import PostmailboxConfirmed from '../views/PostmailboxConfirmedView.vue'
import PasswordResetSuccessView from '../views/PasswordResetSuccessView.vue'
import NewCusEmailSent from '../views/NewCusMailSentView.vue'
import NewCusRegConfirmed from '../views/NewCusRegConfirmed.vue'
import ForetPasswordEmailSentView from '../views/ForgetPassEmailSentView.vue'


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      // blue screen named varient 2
      path: '/',
      component: Variant2View,
    },
    {
      // start page for any user 
      path: '/start',
      name: "startpage",
      component: StartView
    },
    {
      // link expired
      path: '/link-expired',
      component: LinkExpiredView,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromStart_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // token expired
      path: '/email-address-already-verified',
      component: TokenInvalidView
    },
    {
      // token expired
      path: '/user-already-verified',
      component: UserAlreadyRegisteredView
    },
    {
      // nolas account have or not asking page for any user
      path: '/customerview',
      name: "CustomersView",
      component: CustomersView,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromStart_view") === "true" || sessionStorage.getItem("fromNewCustomer_view") === "true" || sessionStorage.getItem("fromExistingCustomer_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // new customer registration mail send form fill view 
      path: '/new-customer',
      component: NewCustomerReg,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromCustomer_view") === "true" || sessionStorage.getItem("fromAddressCreate_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // existing customers address create view 
      path: '/address-create',
      component: AddressCreate,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromNewCustomer_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      //new customer registration mail sent view 
      path: '/new-customer-email-sent',
      component: NewCusEmailSent,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromAddressCreate_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // existing customers authentication required view 
      path: '/existing-auth-confirmed',
      component: ExistingCustomerAuthentication
    },
    {
      // new customer registration mail confirmed view 
      path: '/confirmationwaiting',
      component: ConfirmationWaitingView,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromVerificationRequired_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // existing customers login view 
      path: '/existing',
      component: ExistingCustomerView,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromCustomer_view") === "true" || sessionStorage.getItem("fromAddressMissmatch_view") === "true" || sessionStorage.getItem("fromForgetPasswordEmail_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // Existing customer authentication confirmed view
      path: '/auth-confirmed',
      component: AuthConfirmed,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromExistingCustomer_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // new customer registration confirmed view
      path: '/new-customer-registration-confirmed',
      component: NewCusRegConfirmed
    },
    {
      // postal mailbox confirmed view
      path: '/postal-mailbox-confirmed',
      component: PostmailboxConfirmed,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromStart_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // set new password view
      path: '/set-newpassword',
      component: SetNewPassword
    },
    {
      // password reset sucess view
      path: '/password-reset-success',
      component: PasswordResetSuccessView,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromSetNewPassword_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // existing customer data incorrect view
      path: '/address-mismatch',
      component: AddressMismatch,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromExistingCustomer_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // existing customer verification required view
      path: '/verification-required',
      component: VerificationRequired
    },
    {
      // forget password email sent view
      path: '/forget-password-email',
      component: ForetPasswordEmailSentView,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("fromExistingCustomer_view") === "true" || sessionStorage.getItem("fromForgetPasswordEmail_view") === "true") {
          next();
        } else {
          next('/');
        }
      }
    },

  ]
})


export default router
