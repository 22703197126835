export default {
    // variant 2 view
    Variant2View_1_1: 'digital',
    Variant2View_1_2: 'to digital',
    Variant2View_2: ' Variant 2',
    Variant2View_3: ' New customers',

    // start view
    StartView_1: 'Richte jetzt deinen digitalen Briefkasten ein.',
    StartView_2: 'Über welchen Briefkasten möchtest du deine Dokumente zukünftig erhalten?',
    StartView_3: 'Digitaler Briefkasten',
    StartView_4: 'Postbriefkasten',

    // Customers view
    CustomersView_1_1: 'Hast du bereits einen nolas Account?',
    CustomersView_1_2: '',
    CustomersView_2: 'Na klar',
    CustomersView_3: 'Nein, ich möchte einen erstellen',
    CustomersView_4: 'Zurück',

    // New Customer Registration view
    NewCustomerReg_1: 'Richte jetzt deinen digitalen Briefkasten ein.',
    NewCustomerReg_2: 'Bitte gib deine E-Mail-Adresse an, um deinen digitalen Briefkasten einzurichten.',
    NewCustomerReg_3: 'Wenn du auf "Fertig" klickst, erhältst du eine E-Mail, um deine E-Mail-Adresse zu bestätigen.',
    NewCustomerReg_4: 'E-Mail-Adresse',
    NewCustomerReg_5: 'E-Mail-Adresse wiederholen',
    NewCustomerReg_6: 'Passwort',
    NewCustomerReg_7: 'Passwort wiederholen',
    NewCustomerReg_8_1: 'Ich habe die',
    NewCustomerReg_8_2: 'allgemeinen Geschäftsbedingungen',
    NewCustomerReg_8_3: 'von nolas gelesen und stimme ihnen zu',
    NewCustomerReg_9: 'Weiter',
    NewCustomerReg_10: 'Zurück',
    NewCustomerReg_11: 'Your full name',

    //New customer email sent view
    NewCusEmailSent_1: 'Wir sind auf dem Weg zu dir',
    NewCusEmailSent_2: 'Wir haben den Bestätigungslink an folgende E-Mail-Adresse gesendet:',
    NewCusEmailSent_3: 'Bitte klicke auf den Link in der E-Mail, um deine E-Mail-Adresse zu bestätigen.',
    NewCusEmailSent_4: "E-Mail nicht erhalten?",
    NewCusEmailSent_5: 'Erneut senden',

    //Existing customer auth required view
    ExistingCustomerAuthentication_1: 'Email Adresse bestätigt.',
    ExistingCustomerAuthentication_2: 'In Kürze erhältst du per Post einen Brief mit einem PIN-Code (QR-Code) zur Aktivierung deines digitalen Briefkastens. Durch Scannen des QR-Codes stellen wir sicher, dass nur du Zugriff zu deinem digitalen Briefkasten bei nolas hast.',
    ExistingCustomerAuthentication_3: 'Receive a verification code by post',

    //New customer registration mail confirmed view
    ConfirmationWaitingView_1: 'Boom! Wir warten nun auf deine Bestätigung',
    ConfirmationWaitingView_2: 'In Kürze erhältst du per Post einen Brief mit einem PIN-Code (QR-Code) zur Aktivierung deines digitalen Briefkastens. Durch Scannen des R-Codes stellen wir sicher, dass nur du Zugriff zu deinem digitalen Briefkasten bei nolas hast.',

    //new customer registration confirmed view
    NewCusRegConfirmed_1: 'Willkommen!',
    NewCusRegConfirmed_2: "Ab sofort werden deine Briefe in deinen digitalen Briefkasten zugestellt. Genieße diese und weitere Vorteile, die dein Leben vereinfachen werden. Das alles bietet nolas lifespace – dein ganz persönliches, automatisches Sekretariat.",
    NewCusRegConfirmed_3: 'Lade dir jetzt die mobile App herunter, um auf alle deine Dokumente zuzugreifen.',

    //Existing customer login view
    ExistingCustomerView_1: 'Bitte gib deine Anmeldedaten an.',
    ExistingCustomerView_2: 'Nutzername',
    ExistingCustomerView_3: 'Passwort',
    ExistingCustomerView_4: 'Weiter',
    ExistingCustomerView_5: 'Passwort vergessen?',
    ExistingCustomerView_6: 'Zurück',

    //Existing customer authentication confirmed view
    AuthConfirmed_1: 'Willkommen!',
    AuthConfirmed_2: 'Das Empfangen von Dokumenten in deinem digitalen Briefkasten von nolas wird dein Leben einfacher machen. Du wirst sogar noch mehr Vorteile entdecken.',
    AuthConfirmed_3: 'Lade dir jetzt die mobile App herunter, um auf alle deine Dokumente zuzugreifen.',

    // existing customer data incorrect
    AddressMismatch_1: 'Fehlende Übereinstimmung',
    AddressMismatch_2: 'Es sieht so aus, als hättest du bereits ein Konto mit derselben E-Mail-Adresse, aber einer anderen Wohnadresse. Die Adressangaben aus deinem Schreiben stimmen nicht mit den Adressdaten in unserem System überein.',
    AddressMismatch_3: 'Zurück zur Login',

    // existing customer verification required view
    VerificationRequired_1: 'Identity Verification Required',
    VerificationRequired_2: 'To ensure the security of your account, you must verify your identity to access your digital mailbox. Once verified, you can fully enjoy the benefits of receiving documents digitally without any restrictions.',
    VerificationRequired_3: 'Receive a verification code by post',

    // Link expired view
    LinkExpiredView_1: 'Diese QR-Code wurde bereits verwendet, oder er existiert nicht.',

    // Email address already verified view
    TokenInvalidView_1: 'Der Link wurde bereits bestätigt, und du erhältst die Einladung von uns in Kürze.',

    // User already verified view
    UserAlreadyRegisteredView_1: 'Dein Konto ist bereits verifiziert.',
    UserAlreadyRegisteredView_2: 'Lade dir jetzt die mobile App herunter, um auf alle deine Dokumente zuzugreifen.',

    // Existing customers address create view 
    AddressCreate_1: 'Geben Sie Ihre Adresse an',
    AddressCreate_2: 'Bitte geben Sie Ihre Adresse ein, damit wir Ihnen Ihren Verifizierungscode zusenden und Ihre physische Post in Zukunft umleiten können.',
    AddressCreate_3: 'Land',
    AddressCreate_4: 'Region',
    AddressCreate_5: 'Straße',
    AddressCreate_6: 'Nr.',
    AddressCreate_7: 'Postleitzahl*',
    AddressCreate_8: 'Stadt*',
    AddressCreate_9: 'Adresse',
    AddressCreate_10: 'Fertig',

    // Postal mailbox confirmed view
    PostmailboxConfirmed_1: 'Bist du dir sicher?',
    PostmailboxConfirmed_2: 'Deine Einstellungen wurden gespeichert. Deine Dokumente werden dir weiterhin per Post zugestellt. Wir fragen dich vielleicht später erneut.',
    PostmailboxConfirmed_3: 'Zurück',

    // Set new password view
    SetNewPassword_1: 'Neues Passwort festlegen',
    SetNewPassword_2: 'Nutzername',
    SetNewPassword_3: 'Passwort',
    SetNewPassword_4: 'Passwort bestätigen',
    SetNewPassword_5: 'Passwort zurücksetzen',

    // Password reset sucess view
    PasswordResetSuccessView_1: 'Passwort erfolgreich zurückgesetzt',
    PasswordResetSuccessView_2: 'Danke! Wir haben das Passwort erfolgreich zurückgesetzt. Klicke unten, um deinen digitalen Briefkasten einzurichten.',
    PasswordResetSuccessView_3: 'Digitalen Briefkasten einrichten',

    // Forget password email sent view
    ForetPasswordEmailSentView_1: 'Prüfe deine E-Mails',
    ForetPasswordEmailSentView_2: 'Die Anweisungen zum Zurücksetzen deines Passworts wurden an ',
    ForetPasswordEmailSentView_3: 'gesendet, wenn ein Konto registriert ist. Wenn du keine E-Mail erhalten hast, überprüfe die Schreibweise der E-Mail-Adresse oder lass dir eine neue E-Mail zusenden.',
    ForetPasswordEmailSentView_4: 'Zurück zum Login',
    ForetPasswordEmailSentView_5: 'Erneut senden',

    // loading spinner
    loading_spinner_1:"Gleich geht es los!",
    loading_spinner_2:"Bitte warte einen Moment."


};