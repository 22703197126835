<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  background-color: #FEFAF2 !important;
}

html * {
  font-family: 'Inter', sans-serif;
}

.appbar {
  background-color: transparent;
  color: #EDE8E4;
}

.container {
  text-align: center;
}

.content {
  min-height: calc(100svh - 97px) !important
  /* min-height: calc(100svh) !important */
}

.content-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/epostbox_auth_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-weight: 600;
}

p {
  font-weight: 200;
}

.btn{
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  border: none !important;
  border-radius: 8px !important;
  padding: 14px 10px !important;
  border-radius: 8px;
  max-width: 500px !important;
  margin-bottom: 8px;
}


.btn-nolas-default{
  background-color: #FEFAF2 !important;
  color: black !important;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}

/* .btn-custom {
  width: 100%;
  padding: 14px 10px;
  background-color: #FEFAF2;
  color: black;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
} */

/* .btn-custom:hover {
  background-color: #4373F0;
  color: white;
  animation: bubble 0.4s ease-out;
} */

@keyframes bubble {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.underline {
  display: inline-block;
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.page-title {
  font-weight: 600 !important;
  line-height: 42px !important;
  letter-spacing: -1% !important;
  text-align: center !important;
  margin-bottom: 28px !important;
  margin-top: 0px !important
}

.page-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 28px !important;
  margin-top: 0px !important;
}
</style>
