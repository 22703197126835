export default [
    { id: "1", name: "Deutschland", code: "DE", flag: require('@/assets/flags/germany.png') },
    { id: "2", name: "Niederlande", code: "NL", flag: require('@/assets/flags/netherlands.png') },
    { id: "3", name: "Afghanistan", code: "AF", flag: require('@/assets/flags/afghanistan.png') },
    { id: "4", name: "Albanien", code: "AL", flag: require('@/assets/flags/albania.png') },
    { id: "5", name: "Algerien", code: "DZ", flag: require('@/assets/flags/algeria.png') },
    { id: "6", name: "Andorra", code: "AD", flag: require('@/assets/flags/andorra.png') },
    { id: "7", name: "Angola", code: "AO", flag: require('@/assets/flags/angola.png') },
    { id: "8", name: "Antigua und Barbuda", code: "AG", flag: require('@/assets/flags/antiguaandbarbuda.png') },
    { id: "9", name: "Argentinien", code: "AR", flag: require('@/assets/flags/argentina.png') },
    { id: "10", name: "Armenien", code: "AM", flag: require('@/assets/flags/armenia.png') },
    { id: "11", name: "Australien", code: "AU", flag: require('@/assets/flags/australia.png') },
    { id: "12", name: "Österreich", code: "AT", flag: require('@/assets/flags/austria.png') },
    { id: "13", name: "Aserbaidschan", code: "AZ", flag: require('@/assets/flags/azerbaijan.png') },
    { id: "14", name: "Bahamas", code: "BS", flag: require('@/assets/flags/bahamas.png') },
    { id: "15", name: "Bahrain", code: "BH", flag: require('@/assets/flags/bahrain.png') },
    { id: "16", name: "Bangladesch", code: "BD", flag: require('@/assets/flags/bangladesh.png') },
    { id: "17", name: "Barbados", code: "BB", flag: require('@/assets/flags/barbados.png') },
    { id: "18", name: "Weißrussland", code: "BY", flag: require('@/assets/flags/belarus.png') },
    { id: "19", name: "Belgien", code: "BE", flag: require('@/assets/flags/belgium.png') },
    { id: "20", name: "Belize", code: "BZ", flag: require('@/assets/flags/belize.png') },
    { id: "21", name: "Benin", code: "BJ", flag: require('@/assets/flags/benin.png') },
    { id: "22", name: "Bhutan", code: "BT", flag: require('@/assets/flags/bhutan.png') },
    { id: "23", name: "Bolivien", code: "BO", flag: require('@/assets/flags/bolivia.png') },
    { id: "24", name: "Bosnien und Herzegowina", code: "BA", flag: require('@/assets/flags/bosniaandherzegovina.png') },
    { id: "25", name: "Botsuana", code: "BW", flag: require('@/assets/flags/botswana.png') },
    { id: "26", name: "Brasilien", code: "BR", flag: require('@/assets/flags/brazil.png') },
    { id: "27", name: "Brunei", code: "BN", flag: require('@/assets/flags/brunei.png') },
    { id: "28", name: "Bulgarien", code: "BG", flag: require('@/assets/flags/bulgaria.png') },
    { id: "29", name: "Burkina Faso", code: "BF", flag: require('@/assets/flags/burkinafaso.png') },
    { id: "30", name: "Burundi", code: "BI", flag: require('@/assets/flags/burundi.png') },
    { id: "31", name: "Cabo Verde", code: "CV", flag: require('@/assets/flags/caboverde.png') },
    { id: "32", name: "Kambodscha", code: "KH", flag: require('@/assets/flags/cambodia.png') },
    { id: "33", name: "Kamerun", code: "CM", flag: require('@/assets/flags/cameroon.png') },
    { id: "34", name: "Kanada", code: "CA", flag: require('@/assets/flags/canada.png') },
    { id: "35", name: "Zentralafrikanische Republik", code: "CF", flag: require('@/assets/flags/centralafricanrepublic.png') },
    { id: "36", name: "Tschad", code: "TD", flag: require('@/assets/flags/chad.png') },
    { id: "37", name: "Chile", code: "CL", flag: require('@/assets/flags/chile.png') },
    { id: "38", name: "China", code: "CN", flag: require('@/assets/flags/china.png') },
    { id: "39", name: "Kolumbien", code: "CO", flag: require('@/assets/flags/colombia.png') },
    { id: "40", name: "Komoren", code: "KM", flag: require('@/assets/flags/comoros.png') },
    { id: "41", name: "Republik Kongo", code: "CD", flag: require('@/assets/flags/congo.png') },
    { id: "42", name: "Costa Rica", code: "CR", flag: require('@/assets/flags/costarica.png') },
    { id: "43", name: "Kroatien", code: "HR", flag: require('@/assets/flags/croatia.png') },
    { id: "44", name: "Kuba", code: "CU", flag: require('@/assets/flags/cuba.png') },
    { id: "45", name: "Zypern", code: "CY", flag: require('@/assets/flags/cyprus.png') },
    { id: "46", name: "Tschechien", code: "CZ", flag: require('@/assets/flags/czechrepublic.png') },
    { id: "47", name: "Dänemark", code: "DK", flag: require('@/assets/flags/denmark.png') },
    { id: "48", name: "Dschibuti", code: "DJ", flag: require('@/assets/flags/djibouti.png') },
    { id: "49", name: "Dominica", code: "DM", flag: require('@/assets/flags/dominica.png') },
    { id: "50", name: "Dominikanische Republik", code: "DO", flag: require('@/assets/flags/dominicanrepublic.png') },
    { id: "51", name: "Ecuador", code: "EC", flag: require('@/assets/flags/ecuador.png') },
    { id: "52", name: "Ägypten", code: "EG", flag: require('@/assets/flags/egypt.png') },
    { id: "53", name: "El Salvador", code: "SV", flag: require('@/assets/flags/elsalvador.png') },
    { id: "54", name: "Äquatorialguinea", code: "GQ", flag: require('@/assets/flags/equatorialguinea.png') },
    { id: "55", name: "Eritrea", code: "ER", flag: require('@/assets/flags/eritrea.png') },
    { id: "56", name: "Estland", code: "EE", flag: require('@/assets/flags/estonia.png') },
    { id: "57", name: "Eswatini", code: "SZ", flag: require('@/assets/flags/eswatini.png') },
    { id: "58", name: "Äthiopien", code: "ET", flag: require('@/assets/flags/ethiopia.png') },
    { id: "59", name: "Fidschi", code: "FJ", flag: require('@/assets/flags/fiji.png') },
    { id: "60", name: "Finnland", code: "FI", flag: require('@/assets/flags/finland.png') },
    { id: "61", name: "Frankreich", code: "FR", flag: require('@/assets/flags/france.png') },
    { id: "62", name: "Gabun", code: "GA", flag: require('@/assets/flags/gabon.png') },
    { id: "63", name: "Gambia", code: "GM", flag: require('@/assets/flags/gambia.png') },
    { id: "64", name: "Georgien", code: "GE", flag: require('@/assets/flags/georgia.png') },
    { id: "65", name: "Ghana", code: "GH", flag: require('@/assets/flags/ghana.png') },
    { id: "66", name: "Griechenland", code: "GR", flag: require('@/assets/flags/greece.png') },
    { id: "67", name: "Grenada", code: "GD", flag: require('@/assets/flags/grenada.png') },
    { id: "68", name: "Guatemala", code: "GT", flag: require('@/assets/flags/guatemala.png') },
    { id: "69", name: "Guinea", code: "GN", flag: require('@/assets/flags/guinea.png') },
    { id: "70", name: "Guinea-Bissau", code: "GW", flag: require('@/assets/flags/guineabissau.png') },
    { id: "71", name: "Guyana", code: "GY", flag: require('@/assets/flags/guyana.png') },
    { id: "72", name: "Haiti", code: "HT", flag: require('@/assets/flags/haiti.png') },
    { id: "73", name: "Honduras", code: "HN", flag: require('@/assets/flags/honduras.png') },
    { id: "74", name: "Ungarn", code: "HU", flag: require('@/assets/flags/hungary.png') },
    { id: "75", name: "Island", code: "IS", flag: require('@/assets/flags/iceland.png') },
    { id: "76", name: "Indien", code: "IN", flag: require('@/assets/flags/india.png') },
    { id: "77", name: "Indonesien", code: "ID", flag: require('@/assets/flags/indonesia.png') },
    { id: "78", name: "Iran", code: "IR", flag: require('@/assets/flags/iran.png') },
    { id: "79", name: "Irak", code: "IQ", flag: require('@/assets/flags/iraq.png') },
    { id: "80", name: "Irland", code: "IE", flag: require('@/assets/flags/ireland.png') },
    { id: "81", name: "Israel", code: "IL", flag: require('@/assets/flags/israel.png') },
    { id: "82", name: "Italien", code: "IT", flag: require('@/assets/flags/italy.png') },
    { id: "83", name: "Elfenbeinküste", code: "CI", flag: require('@/assets/flags/ivorycoast.png') },
    { id: "84", name: "Jamaica", code: "JM", flag: require('@/assets/flags/jamaica.png') },
    { id: "85", name: "Japan", code: "JP", flag: require('@/assets/flags/japan.png') },
    { id: "86", name: "Jordanien", code: "JO", flag: require('@/assets/flags/jordan.png') },
    { id: "87", name: "Kasachstan", code: "KZ", flag: require('@/assets/flags/kazakhstan.png') },
    { id: "88", name: "Kenia", code: "KE", flag: require('@/assets/flags/kenya.png') },
    { id: "89", name: "Kiribati", code: "KI", flag: require('@/assets/flags/kiribati.png') },
    { id: "90", name: "Kuwait", code: "KW", flag: require('@/assets/flags/kuwait.png') },
    { id: "91", name: "Kirgisistan", code: "KG", flag: require('@/assets/flags/kyrgyzstan.png') },
    { id: "92", name: "Laos", code: "LA", flag: require('@/assets/flags/laos.png') },
    { id: "93", name: "Lettland", code: "LV", flag: require('@/assets/flags/latvia.png') },
    { id: "94", name: "Libanon", code: "LB", flag: require('@/assets/flags/lebanon.png') },
    { id: "95", name: "Lesotho", code: "LS", flag: require('@/assets/flags/lesotho.png') },
    { id: "96", name: "Liberia", code: "LR", flag: require('@/assets/flags/liberia.png') },
    { id: "97", name: "Libyen", code: "LY", flag: require('@/assets/flags/libya.png') },
    { id: "98", name: "Liechtenstein", code: "LI", flag: require('@/assets/flags/liechtenstein.png') },
    { id: "99", name: "Litauen", code: "LT", flag: require('@/assets/flags/lithuania.png') },
    { id: "100", name: "Luxemburg", code: "LU", flag: require('@/assets/flags/luxembourg.png') },
    { id: "101", name: "Madagaskar", code: "MG", flag: require('@/assets/flags/madagascar.png') },
    { id: "102", name: "Malawi", code: "MW", flag: require('@/assets/flags/malawi.png') },
    { id: "103", name: "Malaysia", code: "MY", flag: require('@/assets/flags/malaysia.png') },
    { id: "104", name: "Malediven", code: "MV", flag: require('@/assets/flags/maldives.png') },
    { id: "105", name: "Mali", code: "ML", flag: require('@/assets/flags/mali.png') },
    { id: "106", name: "Malta", code: "MT", flag: require('@/assets/flags/malta.png') },
    { id: "107", name: "Marshallinseln", code: "MH", flag: require('@/assets/flags/marshallislands.png') },
    { id: "108", name: "Mauritanien", code: "MR", flag: require('@/assets/flags/mauritania.png') },
    { id: "109", name: "Mauritius", code: "MU", flag: require('@/assets/flags/mauritius.png') },
    { id: "110", name: "Mexico", code: "MX", flag: require('@/assets/flags/mexico.png') },
    { id: "111", name: "Föderierte Staaten von Mikronesien", code: "FM", flag: require('@/assets/flags/micronesia.png') },
    { id: "112", name: "Moldavien", code: "MD", flag: require('@/assets/flags/moldova.png') },
    { id: "113", name: "Monaco", code: "MC", flag: require('@/assets/flags/monaco.png') },
    { id: "114", name: "Mongolei", code: "MN", flag: require('@/assets/flags/mongolia.png') },
    { id: "115", name: "Montenegro", code: "ME", flag: require('@/assets/flags/montenegro.png') },
    { id: "116", name: "Marokko", code: "MA", flag: require('@/assets/flags/morocco.png') },
    { id: "117", name: "Mosambik", code: "MZ", flag: require('@/assets/flags/mozambique.png') },
    { id: "118", name: "Myanmar", code: "MM", flag: require('@/assets/flags/myanmar.png') },
    { id: "119", name: "Namibia", code: "NA", flag: require('@/assets/flags/namibia.png') },
    { id: "120", name: "Nauru", code: "NR", flag: require('@/assets/flags/nauru.png') },
    { id: "121", name: "Nepal", code: "NP", flag: require('@/assets/flags/nepal.png') },
    { id: "122", name: "Neuseeland", code: "NZ", flag: require('@/assets/flags/newzealand.png') },
    { id: "123", name: "Nicaragua", code: "NI", flag: require('@/assets/flags/nicaragua.png') },
    { id: "124", name: "Niger", code: "NE", flag: require('@/assets/flags/niger.png') },
    { id: "125", name: "Nigeria", code: "NG", flag: require('@/assets/flags/nigeria.png') },
    { id: "126", name: "Nordkorea", code: "KP", flag: require('@/assets/flags/northkorea.png') },
    { id: "127", name: "Nordmazedonien", code: "MK", flag: require('@/assets/flags/northmacedonia.png') },
    { id: "128", name: "Norwegen", code: "NO", flag: require('@/assets/flags/norway.png') },
    { id: "129", name: "Oman", code: "OM", flag: require('@/assets/flags/oman.png') },
    { id: "130", name: "Pakistan", code: "PK", flag: require('@/assets/flags/pakistan.png') },
    { id: "131", name: "Palau", code: "PW", flag: require('@/assets/flags/palau.png') },
    { id: "132", name: "Staat Palästina", code: "PS", flag: require('@/assets/flags/palestine.png') },
    { id: "133", name: "Panama", code: "PA", flag: require('@/assets/flags/panama.png') },
    { id: "134", name: "Papua-Neuguinea", code: "PG", flag: require('@/assets/flags/papuanewguinea.png') },
    { id: "135", name: "Paraguay", code: "PY", flag: require('@/assets/flags/paraguay.png') },
    { id: "136", name: "Peru", code: "PE", flag: require('@/assets/flags/peru.png') },
    { id: "137", name: "Philippinen", code: "PH", flag: require('@/assets/flags/philippines.png') },
    { id: "138", name: "Polen", code: "PL", flag: require('@/assets/flags/poland.png') },
    { id: "139", name: "Portugal", code: "PT", flag: require('@/assets/flags/portugal.png') },
    { id: "140", name: "Katar", code: "QA", flag: require('@/assets/flags/qatar.png') },
    { id: "141", name: "Rumänien", code: "RO", flag: require('@/assets/flags/romania.png') },
    { id: "142", name: "Russland", code: "RU", flag: require('@/assets/flags/russia.png') },
    { id: "143", name: "Ruanda", code: "RW", flag: require('@/assets/flags/rwanda.png') },
    { id: "144", name: "St. Kitts and Nevis", code: "KN", flag: require('@/assets/flags/saintkittsandnevis.png') },
    { id: "145", name: "St. Lucia", code: "LC", flag: require('@/assets/flags/saintlucia.png') },
    { id: "146", name: "St. Vincent und die Grenadinen", code: "VC", flag: require('@/assets/flags/saintvincentandthegrenadines.png') },
    { id: "147", name: "Samoa", code: "WS", flag: require('@/assets/flags/samoa.png') },
    { id: "148", name: "San Marino", code: "SM", flag: require('@/assets/flags/sanmarino.png') },
    { id: "149", name: "São Tomé und Príncipe", code: "ST", flag: require('@/assets/flags/saotomeandprincipe.png') },
    { id: "150", name: "Saudi-Arabien", code: "SA", flag: require('@/assets/flags/saudiarabia.png') },
    { id: "151", name: "Senegal", code: "SN", flag: require('@/assets/flags/senegal.png') },
    { id: "152", name: "Serbien", code: "RS", flag: require('@/assets/flags/serbia.png') },
    { id: "153", name: "Seychellen", code: "SC", flag: require('@/assets/flags/seychelles.png') },
    { id: "154", name: "Sierra Leone", code: "SL", flag: require('@/assets/flags/sierraleone.png') },
    { id: "155", name: "Singapur", code: "SG", flag: require('@/assets/flags/singapore.png') },
    { id: "156", name: "Slowakei", code: "SK", flag: require('@/assets/flags/slovakia.png') },
    { id: "157", name: "Slovenien", code: "SI", flag: require('@/assets/flags/slovenia.png') },
    { id: "158", name: "Salomonen", code: "SB", flag: require('@/assets/flags/solomonislands.png') },
    { id: "159", name: "Somalia", code: "SO", flag: require('@/assets/flags/somalia.png') },
    { id: "160", name: "Südafrika", code: "ZA", flag: require('@/assets/flags/southafrica.png') },
    { id: "161", name: "Südkorea", code: "KR", flag: require('@/assets/flags/southkorea.png') },
    { id: "162", name: "Südsudan", code: "SS", flag: require('@/assets/flags/southsudan.png') },
    { id: "163", name: "Spanien", code: "ES", flag: require('@/assets/flags/spain.png') },
    { id: "164", name: "Sri Lanka", code: "LK", flag: require('@/assets/flags/srilanka.png') },
    { id: "165", name: "Sudan", code: "SD", flag: require('@/assets/flags/sudan.png') },
    { id: "166", name: "Suriname", code: "SR", flag: require('@/assets/flags/suriname.png') },
    { id: "167", name: "Schweden", code: "SE", flag: require('@/assets/flags/sweden.png') },
    { id: "168", name: "Schweiz", code: "CH", flag: require('@/assets/flags/switzerland.png') },
    { id: "169", name: "Syrien", code: "SY", flag: require('@/assets/flags/syria.png') },
    { id: "170", name: "Tadschikistan", code: "TJ", flag: require('@/assets/flags/tajikistan.png') },
    { id: "171", name: "Tansania", code: "TZ", flag: require('@/assets/flags/tanzania.png') },
    { id: "172", name: "Thailand", code: "TH", flag: require('@/assets/flags/thailand.png') },
    { id: "173", name: "Osttimor", code: "TL", flag: require('@/assets/flags/timorleste.png') },
    { id: "174", name: "Togo", code: "TG", flag: require('@/assets/flags/togo.png') },
    { id: "175", name: "Tonga", code: "TO", flag: require('@/assets/flags/tonga.png') },
    { id: "176", name: "Trinidad and Tobago", code: "TT", flag: require('@/assets/flags/trinidadandtobago.png') },
    { id: "177", name: "Tunesien", code: "TN", flag: require('@/assets/flags/tunisia.png') },
    { id: "178", name: "Türkei", code: "TR", flag: require('@/assets/flags/turkey.png') },
    { id: "179", name: "Turkmenistan", code: "TM", flag: require('@/assets/flags/turkmenistan.png') },
    { id: "180", name: "Tuvalu", code: "TV", flag: require('@/assets/flags/tuvalu.png') },
    { id: "181", name: "Uganda", code: "UG", flag: require('@/assets/flags/uganda.png') },
    { id: "182", name: "Ukraine", code: "UA", flag: require('@/assets/flags/ukraine.png') },
    { id: "183", name: "Vereinigte Arabische Emirate", code: "AE", flag: require('@/assets/flags/unitedarabemirates.png') },
    { id: "184", name: "Vereinigtes Königreich", code: "GB", flag: require('@/assets/flags/uk.png') },
    { id: "185", name: "Vereinigte Staaten", code: "US", flag: require('@/assets/flags/us.png') },
    { id: "186", name: "Uruguay", code: "UY", flag: require('@/assets/flags/uruguay.png') },
    { id: "187", name: "Usbekistan", code: "UZ", flag: require('@/assets/flags/uzbekistan.png') },
    { id: "188", name: "Vanuatu", code: "VU", flag: require('@/assets/flags/vanuatu.png') },
    { id: "189", name: "Vatikanstadt", code: "VA", flag: require('@/assets/flags/vaticancity.png') },
    { id: "190", name: "Venezuela", code: "VE", flag: require('@/assets/flags/venezuela.png') },
    { id: "191", name: "Vietnam", code: "VN", flag: require('@/assets/flags/vietnam.png') },
    { id: "192", name: "Jemen", code: "YE", flag: require('@/assets/flags/yemen.png') },
    { id: "193", name: "Sambia", code: "ZM", flag: require('@/assets/flags/zambia.png') },
    { id: "194", name: "Simbabwe", code: "ZW", flag: require('@/assets/flags/zimbabwe.png') },
  ];
  