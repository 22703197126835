<template>
    <!-- Loading Spinner -->
    <div v-if="loading" class="loading-spinner">
        <div class="spinner"></div>
    </div>

    <!-- Main Content -->
    <div v-else class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">{{ $t('VerificationRequired_1') }}</h1>
                    <p class="page-description" style="margin-top: 32px; margin-bottom: 32px;">
                        {{ $t('VerificationRequired_2') }}
                    </p>
                    <button class="btn btn-blue" @click="buttonRedirect">
                        {{ $t('VerificationRequired_3') }}
                    </button>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import identity from '@/assets/identity.svg';
import AppFooter from '../components/AppFooter.vue';
import axios from 'axios';


export default {
    name: 'VerificationRequired',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            imageSrc: identity,
            loading: true,       // Overall loading state
            dataLoaded: false,   // Tracks if data from API is loaded
            imageLoaded: false,  // Tracks if image is loaded
        };
    },
    async created() {

        sessionStorage.setItem("fromVerificationRequired_view", "true");

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');


        const apiUrl = '/api/customers/getCustomerDetails'
        const body = { token };
        this.token = token;

        await axios.post(apiUrl, body)
            .then(response => {
                if (response.data.status !== 'fail') {
                    if (response.data.properties[0].address !== null) {
                        var street = response.data.properties[0].address.street ? `${response.data.properties[0].address.street} ` : null
                        var houseNo = response.data.properties[0].address.houseNumber ? `${response.data.properties[0].address.houseNumber}, ` : null
                        var zipCode = response.data.properties[0].address.zipCode ? `${response.data.properties[0].address.zipCode} ` : null
                        var city = response.data.properties[0].address.city ? `${response.data.properties[0].address.city}, ` : null
                        var country = response.data.properties[0].address.country ? `${response.data.properties[0].address.country}` : null
                        this.address = street + houseNo + zipCode + city + country
                    }
                    const params = {
                        name: response.data.name,
                        address: this.address,
                        // email: response.data.properties[0].email,
                        token: this.token
                    }
                    localStorage.setItem('requestParams', JSON.stringify(params))
                    this.dataLoaded = true;
                    this.checkIfLoaded()
                }

            }).catch(() => {
                // Redirect if API call fails
            })


        const storedParams = JSON.parse(localStorage.getItem('requestParams'));
        if (storedParams && storedParams.city && storedParams.address_line_1) {
            this.city = storedParams.city;
            this.address_line_1 = storedParams.address_line_1;
        } else {
            this.city = 'City found';
            this.address_line_1 = 'Address not found';
        }
    },
    mounted() {
        // this.openDeepLink();
        // Preload the image
        const img = new Image();
        img.onload = () => {
            this.imageLoaded = true;
            this.checkIfLoaded();
        };
        img.onerror = () => {
            this.imageLoaded = true; // Proceed even if the image fails to load
            this.checkIfLoaded();
        };
        img.src = this.imageSrc;
    },
    methods: {
        buttonRedirect() {
            window.location.href = "/confirmationwaiting";
        },
        checkIfLoaded() {
            if (this.imageLoaded) {
                this.loading = false;
            }
        },
        // openDeepLink() {
        //     const deepLink = "myapp:///verification-required";
        //     window.location.href = deepLink;
        // }
    },
    // beforeRouteLeave(to, from, next) {
    //     if (from.name === null) {
    //         next('/start')
    //     } else {
    //         next()
    //     }
    // }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

button:hover {
    background-color: #0056b3;
}

.btn-blue {
    font-size: 16px !important;
    background-color: #4373F0;
    color: white;
}

/* Spinner Styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
