<template>
    <!-- spinner -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner">
            </div>
        </div>
    </div>

    <!-- Main content -->
    <div v-else class="p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <div class="row m-0 p-0">
                        <h1 class="page-title p-0">{{ $t('SetNewPassword_1') }}</h1>
                        <div class="p-0" style="margin-bottom: 35px; padding-bottom: 0px;">
                            <form @submit.prevent="submitForm">
                                <div style="margin-bottom: 20px;">
                                    <input class="form-control w-100" type="text" v-model="email" :placeholder="$t('SetNewPassword_2')"
                                        style="margin-bottom: 0px;">
                                </div>
                                <div style="margin-bottom: 20px;">
                                    <input class="form-control w-100" type="password" v-model="password"
                                        :placeholder="$t('SetNewPassword_3')" style="margin-bottom: 0px;">
                                    <label v-if="passwordError" style="padding-top: 5px;">{{ passwordError }}</label>
                                </div>
                                <div>
                                    <input class="form-control w-100" type="password" v-model="confirmPassword"
                                        :placeholder="$t('SetNewPassword_4')" style="margin-bottom: 0px;">
                                    <label v-if="confirmPasswordError" style="padding-top: 5px;">{{ confirmPasswordError
                                        }}</label>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-12 btn-wrap p-0" style="margin-bottom: 34px;margin-top: 0px;">
                            <label v-if="errorMessage">{{ errorMessage }}</label>
                            <button class="btn btn-blue m-0" style="margin-top: 1px; padding-top: 0px;"
                                @click="submitForm">{{ $t('SetNewPassword_5') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import Icon from '@/assets/back-icon-black.svg'
import axios from 'axios';

export default {
    name: 'SetNewPassword',
    components: {
        AppNavbar,
    },
    methods: {
        goResetSuccess() {
            this.$router.push('/password-reset-successs');
        },
        submitForm() {
            this.validatePassword();
            if (!this.passwordError && !this.confirmPasswordError) {
                this.loading = true;
                this.resetPassword()
            }
        },
        validatePassword() {
            const password = this.password;
            const minLength = /.{8,}/;
            const uppercase = /[A-Z]/;
            const lowercase = /[a-z]/;
            const digits = /\d.*\d/;
            const specialChar = /[!@#$%^&*(),.?":{}|<>]/;

            // Confirm Password Validation
            if (this.confirmPassword !== this.password) {
                this.confirmPasswordError = 'Die Passwörter stimmen nicht überein.';
                return 'Die Passwörter stimmen nicht überein.';
            } else {
                this.confirmPasswordError = null;
            }

            // Validate if username matches the extracted email
            if (this.username !== this.email) {
                this.emailError = 'Der Benutzername stimmt nicht überein.';

            } else {
                this.emailError = null;
            }

            if (!minLength.test(password)) {
                this.passwordError = 'Password must be at least 8 characters long.';
            } else if (!uppercase.test(password)) {
                this.passwordError = 'Password must contain at least one uppercase letter.';
            } else if (!lowercase.test(password)) {
                this.passwordError = 'Password must contain at least one lowercase letter.';
            } else if (!digits.test(password)) {
                this.passwordError = 'Password must contain at least two numbers.';
            } else if (!specialChar.test(password)) {
                this.passwordError = 'Password must contain at least one special character.';
            } else {
                this.passwordError = null;
            }

        },
        resetPassword() {
            const apiUrl = '/api/customers/password/reset';

            const body = {
                token: this.token,
                newPassword: this.password
            };


            axios.post(apiUrl, body)
                .then(response => {
                    if (response.status === 200 && response.data.status === 'success') {
                        this.$router.push('/password-reset-success');
                    } else if (response.status === 200 && response.data.status === 'fail1') {
                        this.password = null;
                        this.confirmPassword = null;
                        this.errorMessage = response.data.message;
                    } else if (response.status === 200 && response.data.status === 'fail2') {
                        this.password = null;
                        this.confirmPassword = null;
                        this.errorMessage = response.data.message;
                    } else {
                        this.password = null;
                        this.confirmPassword = null;
                        this.errorMessage = "Bitte versuche es noch einmal, etwas stimmt nicht.";
                    }
                })
                .catch(() => {
                    this.password = '';
                    this.confirmPassword = '';
                    this.errorMessage = "Fehler beim Zurücksetzen des Passworts. Bitte versuche es noch einmal.";
                })
                .finally(() => {
                    this.loading = false;
                });

        }
    },
    data() {
        return {
            password: '',
            passwordError: null,
            confirmPasswordError: null,
            confirmPassword: '',
            email: '',
            username: '',
            emailError: null,
            token: '',
            loading: false,
            errorMessage: null,
            showError: true,
            imageSrc2: Icon
        };
    },
    mounted() {
        sessionStorage.setItem("fromSetNewPassword_view", "true");
        const token = this.$route.query.token;
        if (!this.$route.query.email === undefined) {
            const email = decodeURIComponent(this.$route.query.email);
            if (token) {
                this.token = token;
            }
            if (email) {
                this.email = email;
            } else {
                this.email = '';
            }
        }else{
            this.email = '';
        }
    },
};
</script>

<style scoped>
/* spinner styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.one {
    font-weight: 700;
}

.two {
    font-weight: 500;
}

.footer {
    background-color: #EDE8E4;
    border-top: 1px solid #DED8D3;
}

.container-color {
    background-color: #EDE8E4;
}

label {
    color: red;
    margin-top: 1px;
    text-align: left;
    display: block;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 8px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 30px;
    margin: 16px 0;
    margin-bottom: 30px;
}

.content p {
    margin-top: 10px;
    text-align: start;
    color: #FF5454;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

input {
    border-radius: 8px !important;
    font-size: 16px;
    padding: 14px 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(128, 128, 128, 0.08),
        0 0px 1px rgba(128, 128, 128, 0.08);
}

input::placeholder {
    color: #8F8F8F;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #8F8F8F;
}

.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}

a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}


.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper .error-mark {
    position: absolute;
    right: 15px;
    /* Adjust based on your design */
    top: 40%;
    transform: translateY(-50%);
    color: white;
    background-color: #FF5454;
    padding: 0px 7px;
    border-radius: 50%;
    pointer-events: none;
    /* Ensure the exclamation mark doesn't interfere with input */
}
</style>