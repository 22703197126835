<template>
    <div class="p-0">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <div>
                        <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                        <h1 class="page-title">{{ $t('PasswordResetSuccessView_1') }}</h1>
                        <p class="page-description">{{ $t('PasswordResetSuccessView_2') }}
                        </p>
                        <div class="col-md-12 btn-wrap p-0">
                            <button class="btn btn-blue m-0" @click="goToStart">{{ $t('PasswordResetSuccessView_3') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AuthImage from '@/assets/success-icon.svg'
import AppstoreImage from '@/assets/appstore-btn.svg'
import PlaystoreImage from '@/assets/playstore-btn.svg'

export default {
    name: 'PasswordResetSuccessView',
    components: {
        AppNavbar,
    },
    methods: {
        goToStart() {
            sessionStorage.setItem('lastRoute', '/password-reset-success');
            this.$router.push('/start');
        }
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: AuthImage,
            imageSrc2: AppstoreImage,
            imageSrc3: PlaystoreImage
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
    height: 100svh;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>