<template>

    <!-- spinner content -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner">
            </div>
        </div>
    </div>

    <!-- main content -->
    <div v-else class="container p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center" style="margin-top: 97px;">

                <div class="inner-content">
                    <div>
                        <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                        <p class="page-description" style="margin-bottom: 51px !important;">{{ $t('UserAlreadyRegisteredView_1') }}
                        </p>
                        <p style="font-weight: 700; margin-bottom: 28px;">{{ $t('UserAlreadyRegisteredView_2') }}
                        </p>
                    </div>
                    <div class="m-0 p-0" style="margin-bottom: 51px !important; ">
                        <div class="col-md-12">
                            <img :src="imageSrc2" style="margin-bottom: 16px;" alt="Descriptive Text">
                        </div>
                        <div class="col-md-12">
                            <img :src="imageSrc3" alt="Descriptive Text">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AuthImage from '@/assets/success-icon.svg'
import AppstoreImage from '@/assets/appstore-btn.svg'
import PlaystoreImage from '@/assets/playstore-btn.svg'

export default {
    name: 'AuthConfirmed',
    components: {
        AppNavbar,

    },
    data() {
        return {
            // Referencing the imported image
            loading: true,
            dataLoaded: false,
            imageSrc: AuthImage,
            imageSrc2: AppstoreImage,
            imageSrc3: PlaystoreImage
        }
    },
    methods: {
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src
            })
        },
        checkIfLoaded() {
            if (this.dataLoaded) {
                this.loading = false
            }
        },
    },
    created() {
        Promise.all([
            this.preLoadImage(AuthImage),
            this.preLoadImage(AppstoreImage),
            this.preLoadImage(PlaystoreImage)
        ]).then(() => {
            this.dataLoaded = true;
            this.checkIfLoaded();
        }).catch(() => {
            this.checkIfLoaded();
        })
    }
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.container {
    text-align: center;
    height: 100svh;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>