<template>
  <!-- Loading Spinner -->
  <div v-if="loading" class="loading-spinner">
    <div class="spinner"></div>
  </div>

  <!-- Main Content -->
  <div v-else class="p-0 container-color">
    <div class="content">
      <AppNavbar style="color: black;" />

      <div class="d-flex justify-content-center">
        <!-- .inner-content has 32px horizontal margin & max-width 500px -->
        <div class="inner-content text-center">
          <h1 class="page-title">
            {{ $t('AddressCreate_1') }}
          </h1>
          <p class="page-description">
            {{ $t('AddressCreate_2') }}
          </p>

          <!-- CUSTOM DROPDOWN FOR "Land" -->
          <div class="floating-label-group custom-dropdown-container" style="margin-top: 16px; margin-bottom: 0px;">
            <!-- Display area that shows selected country -->
            <div class="dropdown-display fixed-height-input" @click="toggleDropdown" :tabindex="locked ? -1 : 0">
              <div class="selected-value" style="margin-top: 15px;">
                <!-- If a country is selected, show flag + name -->
                <img v-if="selectedCountry && selectedCountry.flag" :src="selectedCountry.flag" class="country-flag" />
                <span v-if="selectedCountry">{{ selectedCountry.name }}</span>
                <span v-else class="placeholder">Land auswählen</span>
              </div>
              <!-- Only show arrow if not locked -->
              <div class="dropdown-arrow" v-if="!locked">
                <img :src="arrowDownIcon" alt="arrow" class="dropdown-icon" />
              </div>
            </div>

            <!-- Floating label for the custom dropdown -->
            <label style="color: #626262;" :class="{ active: dropdownOpen || selectedCountry }">
              {{ $t('AddressCreate_3') }}
            </label>

            <!-- The actual dropdown menu -->
            <transition name="fade">
              <ul v-if="dropdownOpen && !locked" class="dropdown-list" @mousedown.prevent>
                <li v-for="country in countries" :key="country.code" class="dropdown-item"
                  @click="selectCountry(country)">
                  <img :src="country.flag" class="country-flag" />
                  <span>{{ country.name }}</span>
                </li>
              </ul>
            </transition>
          </div>

          <!-- "Region*" Field -->
          <div class="floating-label-group" style="margin-top: 16px;">
            <input type="text" id="regionInput" v-model="regionInput" @focus="regionFocus = true"
              @blur="regionFocus = false" class="fixed-height-input" :readonly="locked" />
            <label :class="{ active: regionFocus || regionInput }" for="regionInput">
              {{ $t('AddressCreate_4') }}
            </label>
          </div>

          <!-- Address Fields (conditionally visible only if selectedCountry.code === 'DE') -->
          <div v-if="selectedCountry && selectedCountry.code === 'DE'" class="row gx-3" style="margin-top: 8px;">
            <!-- Straße -->
            <div class="col-8">
              <div class="floating-label-group">
                <input type="text" v-model="street" @focus="streetFocus = true" @blur="streetFocus = false"
                  class="fixed-height-input" :readonly="locked" />
                <label :class="{ active: streetFocus || street }">
                  {{ $t('AddressCreate_5') }}
                </label>
              </div>
            </div>
            <!-- Hausnummer -->
            <div class="col-4">
              <div class="floating-label-group">
                <input type="text" v-model="houseNumber" @focus="houseNumberFocus = true"
                  @blur="houseNumberFocus = false" class="fixed-height-input" :readonly="locked" />
                <label :class="{ active: houseNumberFocus || houseNumber }">
                  {{ $t('AddressCreate_6') }}
                </label>
              </div>
            </div>

            <span style="margin-top: 8px;"></span>

            <!-- Postleitzahl -->
            <div class="col-4">
              <div class="floating-label-group">
                <input type="text" v-model="postalCode" @focus="postalFocus = true" @blur="postalFocus = false"
                  class="fixed-height-input" :readonly="locked" />
                <label :class="{ active: postalFocus || postalCode }">
                  {{ $t('AddressCreate_7') }}
                </label>
              </div>
            </div>
            <!-- Stadt -->
            <div class="col-8">
              <div class="floating-label-group">
                <input type="text" v-model="city" @focus="cityFocus = true" @blur="cityFocus = false"
                  class="fixed-height-input" :readonly="locked" />
                <label :class="{ active: cityFocus || city }">
                  {{ $t('AddressCreate_8') }}
                </label>
              </div>
            </div>
          </div>
          <!-- "Adresse" Field (for non-DE countries) -->
          <div v-if="selectedCountry && selectedCountry.code !== 'DE'" class="floating-label-group"
            style="margin-top: 16px;">
            <input type="text" id="addressInput" v-model="addressInput" @focus="addressFocus = true"
              @blur="addressFocus = false" class="fixed-height-input" :readonly="locked" />
            <label :class="{ active: addressFocus || addressInput }" for="addressInput">
              {{ $t('AddressCreate_9') }}
            </label>
          </div>
          <!-- End of Address Fields -->

          <div class="col-md-12 btn-wrap p-0 d-flex justify-content-center"
            style="margin-bottom: 30px; margin-top: 40px;">
            <button class="btn btn-blue w-100" @click="submitForm">
              <img :src="imageSrc3" style="margin-bottom: 2px; margin-right: 6px;" alt="Descriptive Text">
              {{ $t('AddressCreate_10') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import ShieldImage from '@/assets/confirm-icon.svg';
import arrowDownIcon from '@/assets/arrowDown.svg';
import countriesData from '@/data/countries.js';
import axios from 'axios';
import tickIcon from '@/assets/Icon(2).svg'


export default {
  name: 'AddressCreateView',
  components: {
    AppNavbar,
  },
  data() {
    return {
      imageSrc: ShieldImage,
      loading: true,
      imageLoaded: false,
      imageSrc3: tickIcon,

      // data from new customer view
      name: '',
      email: '',
      password: '',
      token: '',

      // "Vor- und Nachname*" field
      regionInput: '',
      regionFocus: false,

      // Custom dropdown states
      dropdownOpen: false,
      dropdownFocus: false,

      // The country the user has selected (will be assigned in mounted)
      selectedCountry: null,

      // Import countries from separate file
      countries: countriesData,

      // Address Fields
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      addressInput: '',

      // Focus booleans for address fields
      streetFocus: false,
      houseNumberFocus: false,
      postalFocus: false,
      cityFocus: false,
      addressFocus: false,

      // Arrow icon for the dropdown
      arrowDownIcon,

      // Lock input fields if parameters already exist
      locked: false,
    };
  },
  mounted() {

    sessionStorage.clear();
    sessionStorage.setItem("fromAddressCreate_view", "true");

    const storedParams = JSON.parse(localStorage.getItem('requestParams')) ?? {};
    const UserParams = JSON.parse(localStorage.getItem('userData')) ?? {};

    // Populate form fields from storedParams
    this.street = storedParams.street ?? '';
    this.houseNumber = storedParams.houseNo ?? '';
    this.postalCode = storedParams.zipCode ?? '';
    this.city = storedParams.city ?? '';
    this.regionInput = storedParams.region ?? '';

    // Populate user data
    this.name = UserParams.name;
    this.email = UserParams.email;
    this.password = UserParams.password;
    this.token = UserParams.token;

    // Set the country if provided
    if (storedParams.country) {
      const matchedCountry = this.countries.find(country => country.code === storedParams.country);
      if (matchedCountry) {
        this.selectedCountry = matchedCountry;
      }
    }

    // Lock fields if any address-related parameter exists.
    // (Review this condition – if you don't want to lock the dropdown when only partial data exists, adjust accordingly.)
    if (
      storedParams.address ||
      storedParams.street ||
      storedParams.houseNo ||
      storedParams.zipCode ||
      storedParams.city ||
      storedParams.region ||
      storedParams.country
    ) {
      this.locked = true;
    }

    document.body.style.setProperty('background-color', '#EDE8E4', 'important');

    // Preload image to show spinner until loaded
    const img = new Image();
    img.onload = () => {
      this.imageLoaded = true;
      this.checkIfLoaded();
    };
    img.onerror = () => {
      this.imageLoaded = true;
      this.checkIfLoaded();
    };
    img.src = this.imageSrc;

    // If no country is found from storage, set default country to Deutschland (DE)
    if (!this.selectedCountry) {
      const defaultCountry = this.countries.find(c => c.code === 'DE');
      if (defaultCountry) {
        this.selectedCountry = defaultCountry;
      }
    }
  },
  beforeUnmount() {
    document.body.style.removeProperty('background-color');
  },
  methods: {
    checkIfLoaded() {
      if (this.imageLoaded) {
        this.loading = false;
      }
    },
    openDeepLink() {
      const deepLink = `myapp:///verification-required`;
      window.location.href = deepLink;
    },
    toggleDropdown() {
      // Debug logging
      if (this.locked) return;
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectCountry(country) {
      if (this.locked) return;
      this.selectedCountry = country;
      this.dropdownOpen = false;
    },
    submitForm() {
      this.loading = true;
      const apiUrl = '/api/customers/register';
      let body;
      if (this.selectedCountry.code === 'DE') {
        this.addressInput = ''
      }
      if (this.locked) {
        body = {
          email: this.email,
          password: this.password,
          token: this.token
        };
      } else {
        body = {
          email: this.email,
          password: this.password,
          token: this.token,
          street: this.addressInput !== '' ? '' : this.street,
          houseNumber: this.addressInput !== '' ? '' : this.houseNumber,
          postalCode: this.addressInput !== '' ? '' : this.postalCode,
          city: this.addressInput !== '' ? '' : this.city,
          region: this.regionInput,
          country: this.selectedCountry.code,
          address: this.addressInput
        };
      }

      axios.post(apiUrl, body)
        .then(response => {
          this.loading = false;
          if (response.status === 201) {
            this.$router.push('/new-customer-email-sent');
          } else if (response.status === 200 && response.data.type === "EmailAlreadyUsedError") {
            this.errorMessage = response.data.message;
          } else if (response.status === 200 && response.data.type === "CustomerDataTokenUsedError") {
            this.errorMessage = response.data.message;
          }
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = 'Abgabe fehlgeschlagen. Bitte versuche es später noch einmal.';
        });
    },
  },
};
</script>

<style scoped>
/* Basic fade transition for dropdown */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Container & General Layout */
.container-color {
  background-color: #EDE8E4;
}

.inner-content {
  margin: 0 32px;
  margin-top: 36px;
  max-width: 500px;
  width: 100%;
}

.page-title {
  font-size: 35px;
  margin: 16px 0 8px;
  color: black;
}

.page-description {
  font-size: 16px;
  color: black;
  margin: 0;
  margin-bottom: 16px;
  padding: 0 10px;
}

/* Loading Spinner */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid #6a70ff;
  border-top: 12px solid #EDE8E4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Floating Label Group Styles */
.floating-label-group {
  position: relative;
  margin-top: 8px;
}

.fixed-height-input {
  height: 52px !important;
  padding-top: 20px !important;
  padding-bottom: 12px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.floating-label-group input {
  width: 100%;
  padding: 16px 16px 12px;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.2s;
}

.floating-label-group input:focus {
  border-color: #5d5bf9;
}

.floating-label-group label {
  position: absolute;
  left: 10px;
  top: 16px;
  color: #626262;
  font-size: 14px;
  pointer-events: none;
  transition: all 0.2s ease-out;
  background-color: transparent;
  padding: 0 4px;
}

.floating-label-group label.active {
  top: 6px;
  left: 8px;
  font-size: 10px;
  color: #626262;
  background-color: #FFFFFF;
}

/* CUSTOM DROPDOWN STYLING */
.custom-dropdown-container {
  position: relative;
}

.dropdown-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding: 16px 16px 12px;
  font-size: 16px;
  background-color: #FFFFFF;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
}

.dropdown-display:focus {
  border-color: #5d5bf9;
}

.selected-value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-arrow {
  font-size: 14px;
  margin-left: auto;
}

.dropdown-icon {
  width: 16px;
  height: 16px;
}

.dropdown-list {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: 0;
  padding: 8px 0;
  list-style: none;
  max-height: 210px;
  overflow-y: auto;
  z-index: 999;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f2f2f2;
}

.country-flag {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
}

.placeholder {
  color: #999999;
}

.btn-blue {
  background-color: #4373F0;
  color: white;
}
</style>
