<template>
  <div class="footer">
    <div class="text-center">
      <p class="one">{{ name }}</p>
      
      <p v-if="address" class="two">{{ address }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      name: '',
      address: ''
    }
  },
  created() {
    const storedParams = JSON.parse(localStorage.getItem('requestParams')) ?? '';
    this.name = storedParams.name ?? '';
    this.address = storedParams.address ?? '';
    // Retrieve the email from local storage
    // const storedParams = JSON.parse(localStorage.getItem('requestParams'));
    // if (storedParams && storedParams.name && storedParams.address_line_1) {
    //   this.name = storedParams.name;
    //   this.address_line_1 = storedParams.address_line_1;
    // } else {
    //   this.name = 'Name not found'; // Fallback in case the email is not found
    //   this.address_line_1 = 'Address not found';
    // }
  },
};
</script>

<style scoped>
.one {
  font-weight: 700;
}

.two {
  font-weight: 500;
}

.footer {
  padding-top: 29px;
  padding-bottom: 28px;
  left: 0;
  bottom: 0;
  height: 97px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* position: absolute; */
  /* background-color: #EDE8E4; */
  background-color: #FEFAF2;
  font-size: 14px;
  color: black;
  /* border-top: 1px solid #DED8D3; */
  border-top: 1px solid #F2EADA;
}


.footer p {
  padding: 0px;
  margin: 0px;
}
</style>
