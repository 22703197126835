export default {
    // variant 2 view
    Variant2View_1_1: 'digital',
    Variant2View_1_2: 'to digital',
    Variant2View_2: ' Variant 2',
    Variant2View_3: ' New customers',

    // start view
    StartView_1: 'Set up your digital mailbox now.',
    StartView_2: 'Which kind of mailbox would you prefer for future deliveries of your documents?',
    StartView_3: 'Digital mailbox',
    StartView_4: 'Postal mailbox',

    // Customers view
    CustomersView_1_1: 'Sure, do you have a nolas account?',
    CustomersView_1_2: '',
    CustomersView_2: 'Yes, I do',
    CustomersView_3: 'No, I would like to set up',
    CustomersView_4: 'Go back',

    // New Customer Registration view
    NewCustomerReg_1: 'Set up your nolas digital mailbox now.',
    NewCustomerReg_2: 'Please enter your email address to set up your nolas digital mailbox.',
    NewCustomerReg_3: 'When you click “Submit”, you will receive an email containing a link to confirm your email address.',
    NewCustomerReg_4: 'Enter email address',
    NewCustomerReg_5: 'Repeat email address',
    NewCustomerReg_6: 'Set password',
    NewCustomerReg_7: 'Repeat password',
    NewCustomerReg_8_1: 'I have read the',
    NewCustomerReg_8_2: 'Terms and Conditions',
    NewCustomerReg_8_3: 'of nolas and agree to them',
    NewCustomerReg_9: 'Continue',
    NewCustomerReg_10: 'back',
    NewCustomerReg_11: 'Your full name',


    //New customer email sent view
    NewCusEmailSent_1: 'We are currently on our way to you.',
    NewCusEmailSent_2: 'We have sent a confirmation link to the email address:',
    NewCusEmailSent_3: 'Please click on the link in the email to confirm your email address.',
    NewCusEmailSent_4: "Didn't receive the email? .",
    NewCusEmailSent_5: 'Resend email',

    //Existing customer auth required view
    ExistingCustomerAuthentication_1: 'Your email address has been successfully confirmed.',
    ExistingCustomerAuthentication_2: 'To ensure the security of your account, you must verify your identity to access your digital mailbox. Once verified, you can fully enjoy the benefits of receiving documents digitally without any restrictions.',
    ExistingCustomerAuthentication_3: 'Receive a verification code by post',

    //New customer registration mail confirmed view
    ConfirmationWaitingView_1: 'Boom! We are now waiting for your confirmation',
    ConfirmationWaitingView_2: 'Shortly, you will receive a letter by post with a PIN code (QR code) to activate your digital mailbox. By scanning (photo of QR code) the PIN code, we ensure that only you can access your nolas digital mailbox.',

    //new customer registration confirmed view
    NewCusRegConfirmed_1: 'Welcome!',
    NewCusRegConfirmed_2: "Starting now, your letters will be delivered to your nolas digital mailbox. Enjoy additional benefits designed to simplify your life. That's what nolas lifespace offers - an automatic secretary, just for you.",
    NewCusRegConfirmed_3: 'Download the mobile app now to access your documents.',

    //Existing customer login view
    ExistingCustomerView_1: 'Please enter your login credentials.',
    ExistingCustomerView_2: 'username',
    ExistingCustomerView_3: 'password',
    ExistingCustomerView_4: 'Submit',
    ExistingCustomerView_5: 'Forgot your password?',
    ExistingCustomerView_6: 'Go back',

    //Existing customer authentication confirmed view
    AuthConfirmed_1: 'Welcome!',
    AuthConfirmed_2: 'Receiving documents in your nolas digital mailbox will simplify your life. Discover more benefits with nolas.',
    AuthConfirmed_3: 'Download the mobile app now to access your documents.',

    // existing customer data incorrect
    AddressMismatch_1: 'Mismatch',
    AddressMismatch_2: 'We apologize, but we are unable to activate digital access for you. The address information from your letter does not match the address records in our system.',
    AddressMismatch_3: 'Go back to Login',

    // existing customer verification required view
    VerificationRequired_1: 'Identity Verification Required',
    VerificationRequired_2: 'To ensure the security of your account, you must verify your identity to access your digital mailbox. Once verified, you can fully enjoy the benefits of receiving documents digitally without any restrictions.',
    VerificationRequired_3: 'Receive a verification code by post',

    // Link expired view
    LinkExpiredView_1: 'This QR code has already been used or does not exist.',

    // Email address already verified view
    TokenInvalidView_1: 'The link has already been confirmed, and you will receive the invitation from us shortly.',

    // User already verified view
    UserAlreadyRegisteredView_1: 'Your account is already verified.',
    UserAlreadyRegisteredView_2: 'Download the mobile app now to access all your documents.',

    // Existing customers address create view 
    AddressCreate_1: 'Enter your address',
    AddressCreate_2: 'Please enter your address so that we can send you your verification code and redirect your physical mail in the future.',
    AddressCreate_3: 'Country',
    AddressCreate_4: 'Region',
    AddressCreate_5: 'Street',
    AddressCreate_6: 'No.',
    AddressCreate_7: 'ZIP code*',
    AddressCreate_8: 'City*',
    AddressCreate_9: 'Adress',
    AddressCreate_10: 'Submit',

    // Postal mailbox confirmed view
    PostmailboxConfirmed_1: 'Are you sure?',
    PostmailboxConfirmed_2: 'OK, your settings have been saved. Your documents will continue to be delivered to your postal mailbox. We may ask you about this again later.',
    PostmailboxConfirmed_3: 'Go back',

    // Set new password view
    SetNewPassword_1: 'Set new password',
    SetNewPassword_2: 'username',
    SetNewPassword_3: 'password',
    SetNewPassword_4: 'Confirm password',
    SetNewPassword_5: 'Reset password',

    // Password reset sucess view
    PasswordResetSuccessView_1: 'Password successfully reset',
    PasswordResetSuccessView_2: 'Thank you! We have successfully reset your password. Click below to set up your digital mailbox.',
    PasswordResetSuccessView_3: 'Set up my digital mailbox now',

    // Forget password email sent view
    ForetPasswordEmailSentView_1:'Check your email',
    ForetPasswordEmailSentView_2:'The instructions for resetting your password were sent to',
    ForetPasswordEmailSentView_3:'if an account is registered. If you have not received the email, check the spelling of the email address or ask for the instructions to be resent.',
    ForetPasswordEmailSentView_4:'Go back to Log in',
    ForetPasswordEmailSentView_5:'Resend email',

    // loading spinner
    loading_spinner_1:"It's about to start!",
    loading_spinner_2:"Please wait a moment."

};